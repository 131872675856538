// ============================================
// switchViewport
// ============================================
function switchViewportFunc() {
	const viewport = document.querySelector('meta[name="viewport"]');

	switchFunc();
	window.addEventListener('resize', () => {
		switchFunc();
	});

	function switchFunc() {
		const value = window.outerWidth > 360 ? 'width=device-width,initial-scale=1' : 'width=360';
		if (viewport.getAttribute('content') !== value) {
			viewport.setAttribute('content', value);
		}
	}

}
export { switchViewportFunc };