// ============================================
// gsap scrollTrigger
// ============================================
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

// ============================================
// TOPページMVのパララックス
// ============================================
function mvAnim() {
  const targets = document.querySelectorAll('.js-parallax');
  const mv = document.querySelector('.p-index-mv');

  if (!mv || targets.length === 0) return;

  gsap.utils.toArray('.js-parallax img').forEach((target) => {
    gsap.to(target, {
      objectPosition: '50% 100%',
      scrollTrigger: {
        trigger: mv,
        start: () => 'top 0%',
        end: () => 'bottom 0%',
        scrub: true,
      },
    });
  });
}

// ============================================
// 文字のフェード
// ============================================
function textFade() {
  gsap.utils.toArray('.js-fade').forEach((target) => {
    gsap.to(target, {
      scrollTrigger: {
        trigger: target,
        start: () => 'top 75%',
        toggleClass: {
          targets: target,
          className: 'is-active',
        },
        once: true,
      },
    });
  });
}

// ============================================
// 横線borderありフェード
// ============================================
function textLineFade() {
  gsap.utils.toArray('.js-line-fade').forEach((target) => {
    gsap.to(target, {
      scrollTrigger: {
        trigger: target,
        start: () => 'top 75%',
        toggleClass: {
          targets: target,
          className: 'is-active',
        },
        once: true,
      },
    });
  });
}

// ============================================
// 背景ありフェード
// ============================================
function textBgFade() {
  gsap.utils.toArray('.js-bg-fade').forEach((target) => {
    gsap.to(target, {
      scrollTrigger: {
        trigger: target,
        start: () => 'top 75%',
        toggleClass: {
          targets: target,
          className: 'is-active',
        },
        once: true,
      },
    });
  });
}

// ============================================
// export
// ============================================
export { mvAnim, textFade, textLineFade, textBgFade };
