// ============================================
// c-btn01のアニメーション用
// ============================================
function setSizeBtn() {
  const btnClassArray = ['.c-btn01', '.p-index-faq__btn'].join(',');
  const targets = document.querySelectorAll(btnClassArray);
  if (targets.length === 0) return;

  targets.forEach((target) => {
    const inner = target.querySelector('span');
    const style = window.getComputedStyle(inner);
    const value = style.getPropertyValue('margin-left');

    inner.style.setProperty('--translate-size', value);
  });
}

export { setSizeBtn };
