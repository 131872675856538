// ============================================
// ScrollHint
// ============================================
import ScrollHint from 'scroll-hint';
import 'scroll-hint/css/scroll-hint.css';

function ScrollHintFunc() {
	new ScrollHint('.js-scrollable', {
		i18n: {
			scrollable: 'スクロールできます'
		}
	});
}
export { ScrollHintFunc };