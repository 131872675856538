import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
// ============================================
// top MV news
// ============================================

function slider() {
  const swiper = new Swiper('.p-company-history__slider', {
    // loop: true,
    speed: 600,
    slidesPerView: 1.5,
    navigation: {
      nextEl: '.js-button-next',
      prevEl: '.js-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2.5,
      },
      1228: {
        slidesPerView: 4,
      },
    },
  });
}

// ============================================
// export
// ============================================
export { slider };
