import '../scss/style.scss';

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import '@babel/polyfill';

// ============================================
// モジュール読み込み
// ============================================
import { fixedHeader, drawer } from './lib/header';
import { ScrollHintFunc } from './lib/ScrollHint';
import { switchViewportFunc } from './lib/switchViewport';
//import { pageTop } from './lib/PageTop';
//import { tabFunc } from './lib/Tab';
import { accordionFunc } from './lib/Accordion';
//import { addAutoFunc,numKeyFunc } from './lib/Form';
import { slider } from './lib/Slider';
import { mvAnim, textBgFade, textFade, textLineFade } from './lib/Scroll';
import { setSizeBtn } from './lib/Button';
//import { HeadroomFunc } from './lib/Headroom';

// ============================================
// 実行
// ============================================
window.addEventListener('DOMContentLoaded', () => {
  document.body.classList.add('is-loaded');
  switchViewportFunc();
  fixedHeader();
  drawer();
  ScrollHintFunc();
  //pageTop();
  //tabFunc();
  accordionFunc();
  //addAutoFunc();
  //numKeyFunc();
  //HeadroomFunc();
  setSizeBtn();
});

// ============================================
// ページロード
// ============================================
window.addEventListener('load', () => {
  mvAnim();
  textFade();
  textLineFade();
  textBgFade();
  slider();
});

// ============================================
// リサイズ更新
// ============================================
window.addEventListener('resize', () => {
  setSizeBtn();
});
